@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500&display=swap");

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  background: linear-gradient(#101010, #1e1e1e);
  display: grid;
  place-items: center;
  align-items: flex-end;
  padding: 1rem;
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
}

.button {
  padding: 1rem 2rem;
  color: #2b2f3c;
  background-color: #67e8f9 !important;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  width: max-content;
}

footer {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 4rem 2rem 2rem 2rem;
  align-self: flex-end;
}

footer img {
  max-width: 120px;
}

.connect-wallet {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.initial-message-wrapper {
  font-size: 1.7rem;
  color: white;
}

.welcome-message {
  display: grid;
  gap: 1rem;
  border-bottom: 1px solid white;
  padding-bottom: 1rem;
}

.welcome-message span:nth-child(2) {
  font-weight: bold;
}

.connect-wallet-message {
  margin-top: 1rem;
  font-size: 1.3rem;
}

.connect-wallet-message ol {
  display: grid;
  gap: 1rem;
  text-align: left;
}

.payment-button-wrapper {
  display: grid;
  gap: 1rem;
  justify-content: flex-start;
  align-self: flex-end;
}

.connect-wallet-message ol li {
  padding-left: 1rem;
}

.status {
  display: flex;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.status-icon {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.passed {
  background-color: rgb(29, 233, 29);
}

.error {
  color: rgb(232, 23, 23);
}

.completed {
  background-color: rgb(255, 251, 12);
}

.rejected {
  background-color: rgb(255, 0, 0);
}

.side-bar {
  width: 100%;
  height: 2px;
  background-color: white;
}

.submit {
  padding: 0.5rem 1rem;
  color: white;
  background-color: #1e80ef;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;

  transition: all 0.1s ease-in-out;
  margin-top: 1rem;
}

.not-signed {
  background-color: #e9e9e9;
  cursor: not-allowed;
}

.input-container {
  display: flex;
  align-content: center;
  gap: 0.5rem;
}

.agree-input {
  position: relative;
  cursor: pointer;
  margin: 0;
  margin-right: 0.5rem;
}
.agree-input:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: #e9e9e9;
}
.agree-input:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: #1e80ef;
}
.agree-input:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.DialogOverlay {
  background-color: var(--blackA9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  display: grid;
  gap: 1rem;
  overflow: scroll;
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  font-weight: 500;
  color: var(--mauve12);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve11);
  font-size: 15px;
  line-height: 1.5;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.25rem 0.6rem;
  display: grid;
  place-items: center;
  background-color: #e9e9e9;

  transition: all 0.1s ease-in-out;
}

.IconButton:hover {
  background-color: #1e80ef;
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;

  border: none;
  justify-content: flex-start;
}

.border-top {
  padding-top: 2rem;
  border-top: 2px solid rgba(128, 128, 128, 0.351);
  margin-bottom: 0;
}

.Label {
  font-size: 15px;
  color: var(--violet11);
  width: 100px;

  text-align: right;
}

.Input {
  outline: none;
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: black;
  border: 2px solid rgba(128, 128, 128, 0.351);

  height: 35px;
}
.Input:focus {
  box-shadow: 0 0 0 1px #1e80ef;
}

.message {
  font-size: 1.2rem;
  color: white;
}

.payment-wrapper {
  border-top: 1px solid white;
  margin-top: 1rem;
  padding-top: 1rem;
  display: grid;
  gap: 1rem;
  color: white;
  font-size: 1.2rem;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  footer {
    bottom: 10px;
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
